/*
Version: 1.0
Authors: Krzysztof Krasiński
*/

@keyframes opacityUp {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes opacityDown {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(-100%);
	}
}

@keyframes showElements {
	0% {
		opacity: 0;
		transform: translateY(-30px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes showElementsVisible {
	0% {
		opacity: 0;
		transform: scale(1);
	}
	16% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 1;
		transform: scale(1.1);
	}
}
