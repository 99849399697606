.product-cat {
    &-section {
        margin-bottom: 45px;
        .woocommerce-breadcrumb {
            margin: 0;
        }
    }
    &__image {
        display: flex;
        position: relative;
        &--logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &__description {
        h1 {
            @extend %h4;
            @include mq(medium) {
                padding-top: 0;
            }
        }
        h2 {
            @include mq(medium) {
                padding-bottom: 0;
            }
        }
        .relative {
            @include mq(medium, min) {
                height: 100%;
            }
        }
        .vertical-header {
            @include mq(medium, min) {
                align-items: flex-start;
                justify-content: flex-end;
            }
            &:before {
                @include mq(medium, min) {
                    left: inherit;
                    right: 25px;
                    top: 15px;
                    height: calc(100% - 15px);
                }
            }
            &__title {
                @include mq(medium, min) {
                    padding: 15px 0 50px;
                }
            }
        }
        .wp-content {
            // min-height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    &__count {
        @extend %text-bigger;
        font-weight: 600;
    }
    &__filters {
        display: flex;
        flex-wrap: wrap;

        gap: 15px;
        @include mq(medium, min) {
            justify-content: flex-end;
        }
        select {
            max-width: 150px;
        }
    }
}

.products-result {
    margin-top: 50px;
    .products-more {
        display: flex;
        justify-content: center;
    }
    &__more {
    }
}

.coupons-row {
    padding: 60px 0;
}
