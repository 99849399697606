.homepage-products {
    padding: 65px 0 45px;
    h2 {
        text-align: center;
        margin-bottom: 50px;
        @extend %h5;
    }
}

.homepage-collections {
    &__title {
        @extend %h3;
        margin-bottom: 25px;
    }
    p {
        margin-bottom: 25px;
    }
}
.homepage-menu {
    padding: 60px 0;
    &__title {
        @extend %h4;
        font-weight: 500;
    }
    &__box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        padding: 60px;
        overflow: hidden;
        @include mq(medium) {
            height: 450px;
        }
        @include mq(medium, min) {
            height: 650px;
        }
        @include mq(small, min) {
            &:hover {
                .image-in {
                    transform: scale(1.1);
                }
            }
            &:hover & {
                &--title {
                    transform: translateY(0);
                }
                &--description {
                    transform: translateY(0);
                    opacity: 1;
                }
                &--link {
                    transform: translateY(0);
                    opacity: 1;
                    background-size: 100% 100%;
                }
            }

            &--title {
                transform: translateY(15px);
                transition: all 0.4s ease;
            }
            &--description {
                transform: translateY(15px);
                opacity: 0;
                transition: all 0.4s ease;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            &--link {
                transform: translateY(15px);
                opacity: 0;
                transition: all 0.4s ease;
            }
        }
        .image-in {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.4s ease;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: 0.5;
                z-index: 1;
            }
        }
        &--title {
            position: relative;
            @extend %h3;
            font-weight: 600;
            text-transform: uppercase;
            color: #fff;
            z-index: 1;
            max-width: 320px;
            margin-bottom: 35px;
        }
        &--description {
            position: relative;
            color: #fff;
            z-index: 1;
            max-width: 320px;
        }
        &--link {
            position: relative;
            color: #fff;
            z-index: 1;
            background: linear-gradient(transparent calc(100% - 1px), #fff 10px) no-repeat;
            background-size: auto;
            background-size: 0 100%;
        }
    }
}

.homepage-private {
    margin: 60px 0;
    &.--years {
        margin-top: 0;
    }
    &__part1 {
        padding: 60px 0 160px;
        background: {
            image: url("../img/slowfabrics-list-background.webp");
            size: cover;
            position: center center;
        }
        &--container {
            @include mq(medium, min) {
                display: flex;
                justify-content: center;
            }
        }
    }
    &__part2 {
        margin-top: -120px;
    }
    &__description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: 3;
        svg {
            fill: #fff;
        }
        h3,
        h2 {
            @extend %h5;
            color: #fff;
            text-transform: uppercase;
            margin: 35px 0 20px;
            max-width: 430px;
        }
        p {
            color: #fff;
            max-width: 430px;
            margin-bottom: 25px;
        }
        .btn__block {
            color: #000;
            background: linear-gradient(transparent calc(100% - 1px), #000 10px) no-repeat;
            background-size: calc(50% - 25px) 100%;
            background-position: 25px -10px;
            &:before {
                background-color: #fff;
            }
            &:hover {
                background-size: calc(100% - 50px) 100%;
            }
        }
    }
    &__box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding: 60px;
        overflow: hidden;

        @include mq(medium) {
            height: 450px;
        }
        @include mq(medium, min) {
            height: 650px;
        }

        @include mq(small, min) {
            &:hover {
                .image-in {
                    transform: scale(1.1);
                }
                .btn {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
            &:hover & {
                &--title {
                    transform: translateY(0);
                }
            }

            &--title {
                transform: translateY(15px);
                transition: all 0.4s ease;
            }
            .btn {
                transform: translateY(15px);
                opacity: 1;
                transition: all 0.4s ease;
            }
        }
        .image-in {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.4s ease;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: 0.3;
                z-index: 1;
            }
        }
        &--title {
            position: relative;
            @extend %h5;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            font-weight: 600;
            z-index: 1;
            max-width: 320px;
            margin-bottom: 35px;
        }
        .btn {
            position: relative;
            z-index: 1;
        }
    }
}

.homepage-news {
    padding: 65px 0 45px;
    &__title {
        margin-bottom: 50px;
        @extend %h3;
        text-align: center;
    }
}
.small-news {
    .image-in {
        display: block;
        height: 450px;
        margin-bottom: 25px;
        &:hover {
            img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }
    h3 {
        @extend %h5;
    }
    span {
        display: block;
        @extend %text-smaller;
        color: #7a7b87;
        font-weight: 600;
        margin: 15px 0;
    }
    p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 30px;
    }
}
.homepage-designers {
    padding: 65px 0 45px;
    h3 {
        margin-bottom: 25px;
    }
    p {
        margin-bottom: 25px;
    }
}
.designer-block {
    display: block;
    &__background {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 420px;
        .image-in {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }
    &:hover {
        .image-in img {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
    &:hover & {
        &__logo {
            transform: translateY(-10px);
        }
    }
    &__logo {
        transition: all 0.4s ease;
        z-index: 2;
    }
}
.homepage-icons {
    padding: 65px 0 45px;
}
.newsletter {
    width: 100vw;
    padding: 50px 0;
    background: {
        image: url("../../assets/img/background-gradient-3.svg");
    }
    h3 {
        margin-bottom: 25px;
        text-transform: uppercase;
        font-weight: 600;
    }
    p {
        max-width: 370px;
    }
    &__form {
        &--inputs {
            display: flex;
            gap: 15px;
            margin-bottom: 15px;

            input {
                margin-bottom: 0 !important;
                background-color: transparent !important;
            }
            button {
                text-align: left;
                padding: 10px 25px;
            }
        }
        a {
            font-weight: 500;
            background: linear-gradient(transparent calc(100% - 1px), $font-color 10px) no-repeat;
            background-size: 0 100%;
            &:hover {
                background-size: 100% 100%;
            }
        }
        .wpcf7-acceptance {
            margin: 0;
            font: {
                size: 1.3rem;
                weight: 400;
            }
            color: $font-color;
        }
    }
}
