.about-rosmarie {
    @include mq(small) {
    }
    @include mq(small, min) {
        padding: 60px 0;
    }
    .quote {
        margin: 60px 0 40px;
    }
    p {
        margin-bottom: 25px;
    }
}

.about-history {
    margin: 60px 0;
    padding: 100px 0;
    background: {
        image: url("../img/geschichte-background.webp");
        size: cover;
        position: center center;
    }
    h3 {
        color: #fff;
        max-width: 430px;
        margin-bottom: 50px;
    }
    p {
        color: #fff;
        max-width: 430px;
    }
    .quote {
        fill: #fff;
        margin-bottom: 25px;
    }
    &__quote {
        display: flex;
        flex-direction: column;

        justify-content: center;
    }
    @include mq(medium, min) {
        &__quote {
            align-items: flex-end;
            .quote-text {
                text-align: right;
            }
        }
    }
}

.about-work {
    padding: 60px 0;
    h2 {
        margin-bottom: 15px;
        width: 100%;
        max-width: 600px;
    }
    h3 {
        @extend %h4;
        font-weight: 500;
        width: 100%;
        max-width: 600px;
    }
    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px;
    }
    &__box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        padding: 40px;
        &--logo {
            width: 125px;
            height: 125px;
        }
        &--name {
            @extend %h4;
            font-weight: 600;
            text-transform: uppercase;
            min-height: 138px;
            @include mq(medium) {
                margin: 50px 0 0px;
            }
            @include mq(medium, min) {
                margin: 120px 0 0px;
            }
        }
        &--year {
            @extend %h2;
            font-weight: 600;
        }
        &.--lecoupon {
            background-color: #5a707e;
        }
        &.--lecoupon & {
            &--name,
            &--year {
                color: #fff;
            }
        }
        &.--amachere {
            background-color: #ffd343;
        }
        &.--swiss {
            background-color: #060606;
        }
        &.--swiss & {
            &--name,
            &--year {
                color: #fff;
            }
        }
        &.--couture {
            background-color: #a4447d;
        }
        &.--couture & {
            &--name,
            &--year {
                color: #fff;
            }
        }
    }
}

.about-events {
    padding: 60px 0;
    h3 {
        @include mq(medium) {
        }
        @include mq(medium, min) {
            margin-bottom: 50px;
        }
    }
    &__image {
        img {
            display: block;
            position: sticky;
            margin: 0 auto;
            top: 0;
        }
    }
}

.about-gallery {
}

.about-ex {
    padding: 60px 0;
    h3 {
        @include mq(medium) {
        }
        @include mq(medium, min) {
            margin-bottom: 50px;
        }
    }
    &__image {
        img {
            display: block;
            position: sticky;
            margin: 0 auto;
            top: 0;
        }
    }
}
.about-vision {
    padding: 60px 0;
    h2 {
        margin-bottom: 80px;
    }
    p {
        margin-bottom: 25px;
    }
    .quote {
        margin: 25px 0;
    }
}
