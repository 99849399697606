.press-years {
}

.press-listing {
    @include mq(small) {
        padding: 30px;
    }
    @include mq(small, min) {
        padding: 60px 0;
    }
}

.archive-news {
    padding: 50px 0 80px 0;
}
.archive-single {
    padding: 50px 0 80px;
    &__sidebar-title {
        display: block;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 40px;
    }
    &__ep {
        img {
            margin-bottom: 125px;
        }
        h1 {
            @extend %h4;
        }
        span {
            display: block;
            @extend %text-smaller;
            color: #7a7b87;
            font-weight: 600;
            margin: 15px 0;
        }
    }
}
