.cart {
    &__cart {
        &--header {
            p {
                margin: 25px 0;
            }
            h3 {
                font-weight: 500;
            }
        }
        .woocommerce-cart-form__cart-item {
            &:not(:first-child) {
                border-top: 1px solid #000;
            }
        }
        .product-thumbnail {
        }
        .product-name {
            font-weight: 600;
            max-width: 250px;
            margin-bottom: 25px;
        }
        .product-price {
            display: none;
        }
        .product-quantity {
            width: 80px;
            input {
                pointer-events: none;
                opacity: 0.5;
            }
        }
        .product-subtotal {
            @extend %h5;
        }
        .product-remove {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            svg {
                width: 18px;
                height: 18px;
            }
        }
        .coupon {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            margin-top: 50px;
            label {
                display: none;
            }
            input {
                margin: 0 !important;
                max-width: 250px;
            }
            button {
            }
        }
    }
    &__collaterals {
        margin-top: 50px;
        background-color: $gray-color;
        .cross-sells {
            display: none;
        }
        h2 {
            @extend %h3;
            font-weight: 500;
        }
        table {
            margin: 35px 0;
            th {
                text-align: left;
                padding: 5px 5px 5px 0;
                @extend %h5;
            }
            td {
                text-align: left;
                padding: 5px 5px 5px 15px;
                @extend %h5;
            }

            th {
                font-weight: 400;
            }
            td {
                font-weight: 500;
            }
        }
    }
}
