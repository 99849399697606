input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
select,
textarea {
    display: inline-block;
    padding: 10px 15px;
    background-color: #fff;
    font: {
        family: "Montserrat";
        weight: 400;
        size: 1.4rem;
    }
    line-height: 2.4rem;
    border: 1px solid #000;
    width: 100%;
    margin-bottom: 10px;
    &:focus {
        border: 1px solid #000;
    }
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}
input[type="checkbox"] {
    margin-right: 15px;
}
select {
    appearance: none;
    background: {
        image: url("../../assets/img/ico-drop-down.svg");
        size: 16px 11px;
        position: right 15px center;
        repeat: no-repeat;
    }
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

form {
    label {
        margin-top: 10px;
        span {
            display: block;
            margin-top: 10px;
        }
    }
    span.wpcf7-acceptance {
        margin: 15px;
        label {
            span {
                display: inline;
            }
        }
    }
}

.wpcf7-acceptance {
    display: block;
    font: {
        size: 1.4rem;
    }
    line-height: 2.4rem;
    color: #4c5152;
}
.wpcf7-form {
    p {
        display: block;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
