.btn {
    position: relative;
    display: inline-block;
    @extend %buttonFont;
    transition: all 0.2s ease;
    cursor: pointer;
    padding-bottom: 5px;
    background: linear-gradient(transparent calc(100% - 1px), #000 10px) no-repeat;
    background-size: auto;
    background-size: 50% 100%;
    &:hover {
        background-size: 100% 100%;
    }
    &.--white {
        color: #fff;
        background: linear-gradient(transparent calc(100% - 1px), #fff 10px) no-repeat;
        background-size: 50% 100%;
        &:hover {
            background-size: 100% 100%;
        }
    }
    &__block {
        position: relative;
        padding: 10px 25px;
        color: #fff;
        background: linear-gradient(transparent calc(100% - 1px), #fff 10px) no-repeat;
        background-size: calc(50% - 25px) 100%;
        background-position: 25px -10px;
        border: 0;
        // z-index: 1;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            z-index: -1;
        }
        &:hover {
            background-size: calc(100% - 50px) 100%;
        }
    }

    &__block {
        position: relative;
        padding: 10px 25px;
        color: #fff;
        background: linear-gradient(transparent calc(100% - 1px), #fff 10px) no-repeat;
        background-size: calc(50% - 25px) 100%;
        background-position: 25px -10px;
        border: 0;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            z-index: -1;
        }
        &:hover {
            background-size: calc(100% - 50px) 100%;
        }
    }
}
button[type="submit"],
input[type="submit"],
.button {
    cursor: pointer;
    border: 0;
    position: relative;
    display: inline-block;
    @extend %buttonFont;
    padding: 10px 15px;
    min-width: 180px;
    text-align: center;
    background-color: #000;
    color: #fff;
    transition: all 0.3s ease;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}
