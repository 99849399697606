.search-form {
    h1 {
        @extend %h3;
        margin-bottom: 25px;
    }
    input[type="text"] {
        max-width: 250px;
        margin-bottom: 0 !important;
    }
    border-bottom: 1px solid #ddd;
    &__form {
        width: 100%;
        form div {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 15px;
        }
    }
}
.search-result {
    padding: 50px 0;
    h2 {
        @extend %h3;
        margin-bottom: 25px;
    }
    &__row {
        position: relative;
        margin-bottom: 25px;
        padding-bottom: 25px;
        // &:before {
        //     content: "";
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     width: 100px;
        //     height: 1px;
        //     background-color: #ddd;
        // }
        h2 {
            @extend %h4;
            a {
                &:hover {
                    color: $first-color;
                }
            }
        }
    }
    .not-found {
        padding: 15px 0;
    }
}
