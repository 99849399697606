.account-section {
    margin-bottom: 50px;
}
.woocommerce-MyAccount-navigation {
    padding: 15px;
    ul {
        background-color: $gray-color;
        padding: 45px 0;
        li {
            list-style: none;
            padding: 10px 25px;
            a {
                @extend %text-bigger;
                font-weight: 500;
                &:hover {
                    color: $first-color;
                }
            }
            &.is-active {
                a {
                    color: $first-color;
                }
            }
        }
    }
}
.woocommerce-MyAccount-content {
    @include mq(medium, min) {
        padding-left: 20px;
    }
    .woocommerce-order-details {
        &:before {
            display: none;
        }
    }
}

.woocommerce-MyAccount-orders {
    width: 100%;
    thead {
        display: none;
    }
    tr {
        &:not(:last-child) {
            border-bottom: 1px solid #dcdcdc;
        }
    }
    td {
        display: flex;
        align-items: center;
    }
}
.woocommerce-EditAccountForm,
.woocommerce-address-fields {
    label {
        display: flex;
        align-items: baseline;
    }
    fieldset {
        padding: 15px;
        margin-bottom: 25px;
    }
}

.woocommerce-Addresses {
    header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }
    address {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }
    .edit {
        color: $first-color;
    }
}

.customer_login {
    .woocommerce-form {
        padding: 0;
        label {
            display: flex;
            align-items: baseline;
        }
    }
    &__login {
        padding: 45px 25px;
        background-color: $gray-color;
        height: 100%;
        h2 {
            margin-bottom: 30px;
        }
        input[type="text"],
        input[type="password"] {
            width: 100%;
            max-width: 100%;
        }
    }
    &__register {
        padding: 45px 25px;
        background-color: $gray-color;
        height: 100%;
        h2 {
            margin-bottom: 30px;
        }
    }
}
.woocommerce-ResetPassword {
    padding: 45px 25px;
    background-color: $gray-color;
}
