.designer-list {
    padding: 60px 0;
    .vertical-header {
        @include mq(medium, min) {
            justify-content: flex-end;
        }
    }
    &__menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
        margin-bottom: 50px;
        a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 38px;
            height: 38px;
            border: 1px solid #000;
            font: {
                size: 1.3rem;
                weight: 500;
            }
            line-height: 2.8px;
            text-transform: uppercase;
            &:hover {
                color: #fff;
                background-color: #000;
            }
        }
    }
    &__list {
        width: 100%;
        list-style: none;
        columns: 3;
        margin: 25px 0;
        padding: 0 15px;
        li {
            a {
                @extend %text-smaller;
                font-weight: 500;
                background: linear-gradient(transparent calc(100% - 1px), #000 10px) no-repeat;
                background-size: auto;
                background-size: 0% 100%;
                &:hover {
                    background-size: 100% 100%;
                }
            }
        }
    }
}
