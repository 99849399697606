.contactpage {
    padding: 60px 0;
    object {
        max-width: 100%;
        max-height: 90px;
    }
    .image-in {
        height: 500px;
    }
    h3 {
        @extend %h5;
        margin-bottom: 40px;
    }
    &__big {
        margin-bottom: 50px;
        h2 {
            @extend %h3;
        }
    }
    &__start {
        p {
            margin-bottom: 40px;
        }
    }
    &__showroom {
        h4 {
            @extend %h5;
            margin-bottom: 25px;
        }
    }
    &__logos {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-between;
        margin-top: 50px;
        @include mq(small) {
            flex-direction: column;
            align-items: center;
        }
        object {
            pointer-events: none;
        }
    }
}
.wpcf7-form {
    input[type="text"],
    input[type="email"],
    textarea {
    }
}
