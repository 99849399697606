/*
Version: 1.0
Authors: Krzysztof Krasiński
*/

.hide {
	display: none;
}

.row__padding {
	@include mq(small, min) {
		padding: {
			top: $row-top-padding;
			right: $row-right-padding;
			bottom: $row-bottom-padding;
			left: $row-left-padding;
		}
	}
	@include mq(small) {
		padding: {
			top: calc($row-top-padding - 25px);
			bottom: calc($row-bottom-padding - 25px);
		}
	}
}
.show-on,
.show-on__after {
	opacity: 0;
}

.show-on.in-view {
	animation: showElements 0.6s 0.1s ease-in-out forwards;
}

.show-on__after.in-view {
	animation: showElements 0.6s 0.1s ease-in-out forwards;
}

.relative {
	position: relative;
}
.fullhalf {
	display: flex;
	&__box {
		width: 100%;
		max-width: calc($box-width / 2) - 30px;
	}
	@include mq(medium) {
		justify-content: center;
	}
	@include mq(medium, min) {
		justify-content: flex-end;
	}
}

.goup__show {
	opacity: 1;
	transform: translateY(0px) rotate(-90deg);
}

.goup__hide {
	opacity: 0;
	transform: translateY(-50px) rotate(-90deg);
}

.text-bigger {
	@extend %text-bigger;
}

.vertical-header {
	display: flex;

	&__title {
		position: relative;
		background-color: #fff;
		z-index: 1;
		padding: 15px 0;
		@extend %h2;
		font-weight: 400;
		@include mq(medium, max) {
			background-color: transparent;
		}
	}
	&.--white & {
		&__title {
			color: #fff;
			background-color: transparent;
		}
	}
	&.--top {
		@include mq(medium, max) {
			padding: 15px 0;
		}
	}
	&.--top & {
		&__title {
			@include mq(medium, min) {
				margin-bottom: 50px;
			}
		}
	}
	@include mq(medium, max) {
		//margin-bottom: -40px;
	}
	@include mq(medium, min) {
		position: absolute;
		top: 0;
		bottom: 0;
		justify-content: center;
		align-items: center;
		text-align: center;
		writing-mode: vertical-rl;
		transform: rotate(180deg);
		height: 100%;
		width: 100%;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 50%;
			width: 1px;
			height: 100%;
			background-color: #000;
			z-index: 0;
		}
		&.--white {
			height: auto;
			&:before {
				display: none;
			}
		}
		&.--top {
			justify-content: flex-end;
		}
	}
}

.page-intro {
	padding: 15px 0;
	@include mq(small) {
		height: 250px;
	}
	@include mq(small, min) {
		height: 300px;
	}
	&__block {
		position: relative;
		display: flex;
		align-items: center;
		h1 {
			position: relative;
			z-index: 2;
			object {
				width: 100%;

				max-height: 184px;
			}
			@include mq(medium, min) {
				padding-left: 145px;
				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: 0;
					width: 120px;
					height: 2px;
					background-color: #fff;
				}
			}
		}
		img {
			width: 123px;
			height: 123px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 0;
		}
	}
}

.quote {
	width: 36px;
	height: 28px;
}
.quote-text {
	@extend %h5;
	font-weight: 400;
	text-transform: uppercase;
}

.space {
	&-small {
		margin-bottom: 25px;
	}
	&-medium {
		margin-bottom: 50px;
	}
	&-large {
		margin-bottom: 75px;
	}
}

.post-list {
	padding: 15px 0;
	&:not(:last-child) {
		border-bottom: 1px solid #000;
	}
	&__year {
		font: {
			size: 2.2rem;
		}
		line-height: 3.2rem;
		@include mq(small, min) {
			display: flex;
			justify-content: center;
			writing-mode: vertical-rl;
			transform: rotate(180deg);
		}
	}
	&__title {
		font: {
			size: 2rem;
		}
		line-height: 3rem;
		padding: 10px 0;
	}
	&__logo {
		padding: 10px 0;
		@include mq(small, min) {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&__button {
		@include mq(small, min) {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
}

.baner-wide {
	margin: 60px 0;
	padding: 80px;
	h3 {
		@extend %h2;
		font-weight: 500;
		color: #fff;
	}
}

.sm-slider {
	// position: relative;
	// width: 100%;
	// overflow: hidden;
	// &.--years {
	// 	max-width: 500px;
	// 	height: 60px;
	// 	.sm-slide {
	// 		a {
	// 			font: {
	// 				size: 2.2rem;
	// 				weight: 600;
	// 			}
	// 			opacity: 0.7;
	// 			&:hover {
	// 				opacity: 1;
	// 			}
	// 		}
	// 	}
	// }
	// &.--years & {
	// 	&__box {
	// 		height: 60px;
	// 	}
	// }
	&.--images {
		// height: 350px;
		// @include mq(medium) {
		// 	height: 350px;
		// }
		// @include mq(medium, min) {
		// 	height: 350px;
		// }
		// .sm-slider__box {
		// 	height: 100%;
		// }
	}
	// &__box {
	// 	position: absolute;
	// 	display: flex;
	// 	transition: all 0.4s ease;
	// 	left: 0;
	// }
	// &__prev {
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 0;
	// 	transform: translateY(-50%);
	// 	font-size: 4rem;
	// 	opacity: 0.5;
	// 	transition: all 0.4s ease;
	// 	cursor: pointer;
	// 	width: 13px;
	// 	height: 32px;
	// 	z-index: 2;
	// 	svg {
	// 		width: 13px;
	// 		height: 25px;
	// 	}
	// }
	// &__next {
	// 	position: absolute;
	// 	top: 50%;
	// 	right: 0;
	// 	transform: translateY(-50%);
	// 	font-size: 4rem;
	// 	opacity: 0.5;
	// 	transition: all 0.4s ease;
	// 	cursor: pointer;
	// 	width: 13px;
	// 	height: 32px;
	// 	z-index: 2;
	// 	svg {
	// 		width: 13px;
	// 		height: 25px;
	// 	}
	// }
	&__nav {
		position: absolute;
		top: 0;
		width: calc(100% - 30px);
		height: 100%;
		pointer-events: none;
		button {
			background-color: transparent;
			border: 0;
			padding: 0;
			margin: 0;
			width: 15px;
			height: 25px;
			svg {
				width: 15px;
				height: 25px;
			}
		}
		&--prev {
			position: absolute;
			top: 50%;
			left: 0;
			cursor: pointer;
			pointer-events: initial;
		}
		&--next {
			position: absolute;
			top: 50%;
			right: 0;
			cursor: pointer;
			pointer-events: initial;
		}
	}
}

// .sm-slider__prev > img,
// .sm-slider__next > img {
// 	pointer-events: none;
// }
// .sm-slider__prev:hover,
// .sm-slider__next:hover {
// 	opacity: 1;
// }
// .sm-slide {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	img {
// 		display: block;
// 		margin: 0 auto;
// 		width: auto;
// 		height: auto;
// 	}
// }

.screen-reader-response {
	display: none;
}
.wpcf7-response-output {
	display: none;
	padding: 15px;
	background-color: #ea4d4d;
	color: #fff;
	margin-bottom: 15px;
}
.wpcf7-not-valid-tip {
	font-size: 1.4rem;
	line-height: 2.6rem;
	color: #ea4d4d;
}

.gm-style img {
	max-height: none;
}

.image-in {
	position: relative;
	overflow: hidden;
	img {
		position: absolute;
		top: 50%;
		min-width: 100%;
		min-height: 100%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: all 0.4s ease;
		object-fit: cover;
	}
}

.tab-list {
	&__summary {
		padding: 15px 15px 15px 45px;
		background: {
			size: 16px 16px;
			position: center left;
			repeat: no-repeat;
		}
		cursor: pointer;
		h3 {
			@extend %text-bigger;
			font-weight: 500;
			text-transform: uppercase;
		}
		&.active {
			background-image: url("../../assets/img/ico-cross.svg");
			& + .tab-list__details {
				display: block;
			}
		}
		&:not(.active) {
			background-image: url("../../assets/img/ico-plus.svg");
		}
	}
	&__details {
		padding: 15px 15px 20px;
		display: none;
	}
}

%wp-content {
	ul {
		list-style: none;
		margin-bottom: 15px;
		li {
			position: relative;
			padding: 3px 0 3px 30px;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 16px;
				width: 14px;
				height: 1px;
				background-color: #000;
			}
		}
	}
	ol {
		li {
			margin-left: 15px;
			padding: 3px 0 3px 10px;
			&::marker {
				color: #000;
			}
		}
	}

	h1,
	h2 {
		padding-bottom: 25px;
		padding-top: 25px;
	}
	h3,
	h4,
	h5 {
		padding-top: 15px;
		padding-bottom: 25px;
	}
	a {
		color: #000;
		&:hover {
			text-decoration: underline;
		}
	}
	img {
		display: block;
		margin: 10px auto;
	}
	.magic-line {
		margin-bottom: 25px;
	}
}

.wp-content {
	@extend %wp-content;
}
.row-gray {
	background-color: #f5f5f5;
	padding: 25px 0 40px;
	margin: 50px 0;
}
ul.list {
	list-style: none;
	margin-bottom: 15px;
	li {
		position: relative;
		padding: 3px 0 3px 20px;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 8px;
			width: 8px;
			height: 14px;
			background: {
				image: url("../img/ul-arrow.svg");
				size: contain;
				position: center center;
				repeat: no-repeat;
			}
		}
	}
}
ol.list {
	list-style: none;
	margin-bottom: 15px;
}

.rodo {
	position: fixed;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	background-color: #f5f5f5;
	opacity: 0;
	z-index: 99999;
	max-width: 230px;
	padding: 10px 10px 15px 10px;

	.rodo__content {
		font: {
			size: 1.2rem;
		}
		padding: 10px;

		.link {
			color: #000;
			&:hover {
				text-decoration: underline;
			}
		}
		strong {
			display: block;
			font: {
				family: "Montserrat", Verdana;
				weight: 600;
				size: 1.6rem;
				weight: 400;
			}
			line-height: 2.8rem;
		}
	}

	.rodo__navigation {
		padding: 10px;
		display: flex;
		flex-direction: column;

		a {
			font: {
				size: 1.3rem;
				weight: 600;
			}

			line-height: 1.3rem;
			padding: 10px 10px 10px 0;
		}
		.accept {
			&:hover {
				color: #000;
			}
		}
		.refuse {
			&:hover {
				color: #000;
			}
		}
	}
}

.rodo__show {
	animation: opacityUp 0.4s ease-in-out forwards;
}

.rodo__hide {
	animation: opacityDown 0.4s ease-in-out forwards;
}

.page-numbers {
	display: flex;
	list-style: none;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	li {
		padding: 5px;
	}

	.current {
		color: #000;
	}

	a:not(.next):not(.prev),
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		height: 35px;
		border: 1px solid #000;
		&:hover {
			color: #fff;
			background-color: #000;
		}
	}

	.next {
		margin-left: 10px;
	}

	.prev {
		margin-right: 10px;
	}
}

.scrolltop {
	position: fixed;
	display: flex;
	align-items: center;
	right: 0;
	bottom: 45px;
	cursor: pointer;
	transition: all 0.5s ease;
	z-index: 999;
	background-color: rgba(255, 255, 255, 0.5);
	padding: 0 10px;
	font: {
		size: 1.4rem;
		weight: 400;
	}
	color: #2c2c2c;
	text-transform: uppercase;

	.ico {
		width: 19px;
		height: 7px;
		margin-left: 10px;
		transition: all 0.4s ease;
	}
	&:hover {
		.ico {
			transform: translateX(8px);
		}
	}
}

.goup__show {
	opacity: 1;
	transform: translateY(0px) rotate(-90deg);
}

.goup__hide {
	opacity: 0;
	transform: translateY(-50px) rotate(-90deg);
}

.window,
.windowv {
	position: fixed;
	z-index: 9999;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: rgba(0, 0, 0, 0.85);
	opacity: 0;
	transform: translateY(-200px);
	pointer-events: none;
	transition: all 0.4s ease;
	display: flex;
	justify-content: center;
	align-items: center;

	&__content {
		display: flex;
		// align-items: center;
		background-color: #fff;
		img {
			display: block;
			max-height: 100%;
			max-width: 100%;
			width: auto;
			margin: 0 auto;
		}
		iframe {
			width: 80vw;
			height: 80vh;
		}
		.form-title {
			margin-bottom: 25px;
		}
	}

	&__close {
		position: absolute;
		top: 2.5vh;
		right: 5vw;

		font: {
			size: 3rem;
		}

		cursor: pointer;
		color: #fff;
		transition: all 0.4s ease;

		&:hover {
			color: #000;
		}
	}

	&.active {
		opacity: 1;
		transform: translateY(0);
		pointer-events: auto;
	}

	&__video {
		padding: 25px 35px;
		.windowv__content {
			width: 100%;
			height: 100%;
		}
		iframe {
			width: 100%;
			height: 100%;
		}
	}
	&__form {
		padding: 35px;
		.window__content {
			position: relative;
			padding: 30px;
			max-width: $box-width;
			min-height: 600px;
			max-height: 80vh;
			overflow: auto;
			.window__close {
				color: $font-color;
			}
		}
	}
}
.window {
	&__close {
		top: 0;
		right: 15px;
	}
}

.baner {
	padding: 80px 0 60px;
	margin: 50px;
	background: {
		image: url("../img/background-gradient-3.svg");
	}
	h2 {
		font-size: 2.8rem;
		font-weight: 600;
		line-height: 3.8rem;
		text-transform: uppercase;
		margin-bottom: 25px;
		text-align: center;
	}
	p {
		margin-bottom: 25px;
		text-align: center;
	}
	&__buttons {
		display: flex;
		justify-content: center;
		position: relative;
		flex-wrap: wrap;
		z-index: 2;
		gap: 15px;
	}
}
