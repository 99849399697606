@mixin gradient($from, $color1, $color2) {
  background: $color1; /* Old browsers */
  background: -moz-linear-gradient($from, $color1 0%, $color2 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient($from, $color1 0%, $color2 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient($from + 180deg, $color1 0%, $color2 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr="$color1",
      endColorstr="$color2",
      GradientType=1
    ); /* IE6-9 fallback on horizontal gradient */
}

@mixin font-family($name, $file, $ext) {
  $filePath: "../fonts/" + $file + "";
  @font-face {
    font-family: "#{$name}";
    font-display: auto;
    src: url($filePath + "." + $ext);
  }
}

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
