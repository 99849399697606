* {
	margin: 0;
	padding: 0;
	text-decoration: none;
	box-sizing: border-box;
	outline: none;
}
a {
	cursor: pointer;
	text-decoration: none;
}
img {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}
