.woocommerce-form-coupon {
    padding: 25px 15px;
    background-color: $gray-color;
    @include mq(small) {
        text-align: center;
    }
    &-toggle {
        .woocommerce-info {
            a {
                color: $first-color;
                font-weight: 500;
                background: linear-gradient(transparent calc(100% - 1px), $first-color 10px) no-repeat;
                background-size: auto;
                background-size: 0 100%;
                &:hover {
                    background-size: 100% 100%;
                }
            }
        }
    }
    &-inputs {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        input {
            max-width: 250px;
            margin: 0 !important;
        }
        @include mq(small) {
            justify-content: center;
        }
    }
}
.woocommerce-form-login {
    padding: 25px 15px;
    background-color: $gray-color;
    &-toggle {
        .woocommerce-info {
            a {
                color: $first-color;
                font-weight: 500;
                background: linear-gradient(transparent calc(100% - 1px), $first-color 10px) no-repeat;
                background-size: auto;
                background-size: 0 100%;
                &:hover {
                    background-size: 100% 100%;
                }
            }
        }
    }

    input {
        max-width: 250px;
        margin: 0 10px 0 0 !important;
    }

    label {
        display: flex;
        align-items: baseline;
    }
    .woocommerce-form-login__submit {
        margin-top: 15px;
    }
}
.woocommerce-checkout {
    .woocommerce-NoticeGroup-checkout {
        width: 100%;
        padding: 15px;
        .woocommerce-error {
            width: 100%;
            padding: 15px;
            background-color: $gray-color;
            margin-bottom: 15px;
            li {
                list-style: none;
            }
        }
    }
    label {
        display: flex;
        align-items: baseline;
    }
    &__header {
        h2 {
            @extend %h3;
            margin-bottom: 50px;
        }
        p {
            margin-bottom: 80px;
        }
    }
    .woocommerce-billing-fields {
        h3 {
            @extend %h4;
            margin-bottom: 25px;
        }
        label span {
            display: inline;
            margin: 0;
        }
    }
    .woocommerce-shipping-fields {
        h3 {
            @extend %h4;
            margin-bottom: 25px;
        }
        label span {
            display: inline;
            margin: 0;
        }
    }
    .woocommerce-additional-fields {
        h3 {
            @extend %h4;
            margin-bottom: 25px;
        }
        label span {
            display: inline;
            margin: 0;
        }
        #nip-check {
        }
        #nip_field {
            display: none;
        }
    }

    .checkout-order-review {
        position: relative;
        background-color: $gray-color;
        padding: 50px 15px;
        margin-top: 50px;
        h3 {
            font-weight: 500;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100vw + 30%);
            height: 100%;
            transform: translateX(-50%);
            background-color: $gray-color;
            z-index: -1;
        }
        table {
            margin: 35px 0;
            thead {
                @include mq(small) {
                    display: none;
                }
            }
            th {
                text-align: left;
                padding: 5px 5px 5px 0;
                //@extend %h5;
            }
            tr {
                &.cart_item {
                    @include mq(small) {
                        display: flex;
                        flex-direction: column;
                        width: 320px;
                    }
                }
            }
            td {
                text-align: left;
                padding: 5px 5px 5px 0;
                //@extend %h5;
            }

            th {
                font-weight: 400;
            }
            td {
                font-weight: 500;
            }
        }
        .cart-subtotal {
            @include mq(small) {
                display: flex;
                flex-direction: column;
                //width: 320px;
            }
        }
        .woocommerce-shipping-totals {
            ul {
                list-style: none;
                li {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    label {
                        margin: 0;
                        span {
                            display: inline;
                            margin: 0;
                        }
                    }
                }
            }
            @include mq(small) {
                display: flex;
                flex-direction: column;
                //width: 320px;
            }
        }
        .order-total {
            @include mq(small) {
                display: flex;
                flex-direction: column;
                //width: 320px;
            }
        }
        .woocommerce-checkout-payment {
            ul {
                list-style: none;
                margin: 25px 0 50px;
                li {
                    padding-bottom: 10px;
                    label {
                        //@extend %h5;
                        font-weight: 500;
                    }
                    .payment_box {
                        // @extend %footerHeader;
                        font-weight: 400;
                        p {
                            max-width: 700px;
                            margin-bottom: 0;
                        }
                    }
                    input {
                        display: block;
                        float: left;
                        margin-right: 10px;
                        top: 6px;
                        position: relative;
                    }
                }
            }
        }
    }
}

.wc_rules_checkbox {
    label {
        display: inline;
    }
    a {
        font-weight: 500;
        background: linear-gradient(transparent calc(100% - 1px), #000 10px) no-repeat;
        background-size: auto;
        background-size: 0 100%;
        &:hover {
            background: linear-gradient(transparent calc(100% - 1px), #000 10px) no-repeat;
            background-size: 100% 100%;
        }
    }
}
