/*
Version: 1.0
Authors: Krzysztof Krasiński
*/

html {
  font-size: $font-size;
}

%fontMenu {
  font: {
    family: "Montserrat";
    size: 1.5rem;
    weight: 600;
  }
  line-height: 2.8rem;
}
%h1 {
  font: {
    family: "Montserrat";
    weight: 400;
  }
  color: $font-color;
  line-height: 5.4rem;
  @include mq(small) {
    font: {
      size: 4.2rem;
    }
  }
  @include mq(small, min) {
    font: {
      size: 4.2rem;
    }
  }
}
%h2 {
  font: {
    family: "Montserrat";
    weight: 400;
  }
  color: $font-color;
  line-height: 5.2rem;
  @include mq(small) {
    font: {
      size: 4.2rem;
    }
  }
  @include mq(small, min) {
    font: {
      size: 4.2rem;
    }
  }
}
%h3 {
  font: {
    family: "Montserrat";
    weight: 400;
  }
  color: $font-color;
  line-height: 4.2rem;
  @include mq(small) {
    font: {
      size: 3.2rem;
    }
  }
  @include mq(small, min) {
    font: {
      size: 3.2rem;
    }
  }
}
%h4 {
  font: {
    family: "Montserrat";
    weight: 400;
  }
  color: $font-color;
  line-height: 3.6rem;
  @include mq(small) {
    font: {
      size: 2.6rem;
    }
  }
  @include mq(small, min) {
    font: {
      size: 2.6rem;
    }
  }
}
%h5 {
  font: {
    family: "Montserrat";
    weight: 600;
  }
  color: $font-color;
  line-height: 3.2rem;
  @include mq(small) {
    font: {
      size: 1.8rem;
    }
  }
  @include mq(small, min) {
    font: {
      size: 2.2rem;
    }
  }
}

%buttonFont {
  font: {
    family: "Montserrat";
    size: 1.6rem;
    weight: 500;
  }
  line-height: 2.7rem;
}
%footerHeader {
  font: {
    family: "Montserrat";
    size: 1.8rem;
    weight: 500;
  }
  line-height: 2.8rem;
}

%text-smaller {
  font: {
    family: "Montserrat";
    size: 1.4rem;
    weight: 400;
  }
  line-height: 2.5rem;
}
%text {
  font: {
    family: "Montserrat";
    size: 1.5rem;
    weight: 400;
  }
  line-height: 2.6rem;
}
%text-bigger {
  font: {
    family: "Montserrat";
    size: 1.6rem;
    weight: 400;
  }
  line-height: 2.7rem;
}
body {
  @extend %text;
  color: $font-color;
}

h1 {
  @extend %h1;
}
h2 {
  @extend %h2;
}
h3 {
  @extend %h3;
}
h4 {
  @extend %h4;
}
h5 {
  @extend %h5;
}

a {
  transition: all 0.4s ease;
  color: $font-color;
}
p {
  margin-bottom: 15px;
}
