.product:not(li) {
    position: relative;
    width: 100%;
    max-width: $box-width;
    display: flex;
    flex-wrap: wrap;
    > .onsale {
        position: absolute;
        top: -20px;
        left: 15px;
        z-index: 20;
    }
    > .new {
        position: absolute;
        top: -20px;
        z-index: 20;

        &:not(.--s) {
            left: 15px;
        }
        &.--s {
            left: 107px;
        }
    }
}

.woocommerce-product-gallery {
    position: relative;
    display: flex;
    padding: $col;
    @include mq(small) {
        flex-direction: column;
    }
    @include mq(small, min) {
        flex-direction: row-reverse;
        width: 100%;
    }
    @include mq(medium, min) {
        flex-direction: row-reverse;
        width: 60%;
    }
    @include mq(large, min) {
        flex-direction: row-reverse;
        width: 50%;
    }
    img {
        height: auto;
    }
    .flex-viewport {
        @include mq(small, max) {
        }
        @include mq(small, min) {
            width: 88%;
        }
    }
    .flex-control-nav {
        width: 20%;
        @include mq(small) {
            display: flex;
            flex-direction: row;
            width: 100%;
        }
        li {
            list-style: none;
            cursor: pointer;
            @include mq(small) {
                //width: 25%;
            }
        }
    }
}

.summary {
    padding: $col;
    @include mq(large) {
        width: 100%;
    }
    @include mq(medium, min) {
        width: 40%;
    }
    @include mq(large, min) {
        width: 50%;
    }
    .single-product-company {
        @extend %text-bigger;
        font-weight: 600;
        text-transform: uppercase;
        &:hover {
            color: $first-color;
        }
    }
    .product_title {
        @extend %h3;
        font-weight: 500;
    }
    .sku_wrapper {
        display: block;
        @extend %text-smaller;
        color: $font-color-gray;
        margin-bottom: 50px;
    }
    .woocommerce-product-details__short-description {
        margin-bottom: 50px;
    }
    .woocommerce-product-attributes {
        &:not(.--nomargin) {
            margin-bottom: 50px;
        }

        tr {
            th {
                @extend %text-smaller;
                line-height: 2rem;
                font-weight: 600;
                color: $font-color-gray;
                text-align: left;
                padding: 5px 5px 5px 0;
                text-transform: uppercase;
            }
            td {
                @extend %text-smaller;
                line-height: 2rem;
                font-weight: 600;
                color: #000;
                padding: 5px 5px 5px 0;
                text-transform: uppercase;
                p {
                    margin: 0;
                }
            }
        }
    }
    .price {
        display: flex;
        flex-direction: column;
        font: {
            size: 2.2rem;
            weight: 600;
            color: #000;
        }
        del {
            @extend %text-smaller;
            font-weight: 600;
            color: $first-color;
            text-decoration: line-through;
        }
        ins {
            font: {
                size: 2.2rem;
                weight: 600;
                color: #000;
            }
        }
    }
    .stock.in-stock {
    }
    .cart {
        .quantity {
            &__m {
                @extend %buttonFont;
                margin-left: 10px;
            }
            .screen-reader-text {
            }
            input {
                width: auto;
            }
        }
        .single_add_to_cart_button {
            position: relative;
            display: inline-flex;
            align-items: center;
            @extend %buttonFont;
            transition: all 0.2s ease;
            cursor: pointer;
            padding: 15px 25px;
            color: #fff;
            background-color: #000;
            border: 0;
            &:after {
                position: relative;
                display: flex;
                align-items: center;
                content: url("../../assets/img/ico-basket-btn.svg");
                margin-left: 15px;
                top: -1px;
            }
        }
    }
}

.single-product-icons {
    display: inline-block;
    margin: 50px 0;
    &__row {
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid #dcdcdc;
        @extend %text-bigger;
        text-transform: uppercase;
        font-weight: 600;
        &:first-child {
            border-top: 1px solid #dcdcdc;
        }
        &--images {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 15px;
            gap: 5px;
        }
        object {
            width: 14px;
            height: 14px;
            margin-right: 15px;
        }
        img {
            height: 20px;
        }
    }
}

.woocommerce-tabs {
    position: relative;
    width: 100%;
    padding: $col;
    .tabs {
        position: relative;
        border-bottom: 1px solid #000;
        &:before {
            position: absolute;
            content: " ";
            width: 100%;
            height: 1px;
            bottom: -1px;
            left: -100%;
            background-color: #000;
            z-index: -1;
        }
        &:after {
            position: absolute;
            content: " ";
            width: 100%;
            height: 1px;
            bottom: -1px;
            right: -100%;
            background-color: #000;
            z-index: -1;
        }
        li {
            display: inline-block;
            position: relative;
            z-index: 0;
            transition: all 0.4s ease;
            &.active {
                background: #000;
                a,
                h2 {
                    color: #fff;
                }
                z-index: 2;
            }
            a {
                display: inline-flex;
                padding: 15px 25px;
                h2 {
                    font-size: 1.5rem;
                    line-height: 2.6rem;
                    color: #000;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-align: center;
                }
            }
        }
    }
    .woocommerce-Tabs-panel {
        padding: 50px 0;
        @extend %wp-content;
        max-width: 1200px;
    }
}

.related.products {
    padding: $col;
    width: 100%;
    margin-bottom: 50px;
    h3 {
        margin-bottom: 50px;
        text-align: center;
        font-weight: 500;
    }
}
