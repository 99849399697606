.small-product {
    position: relative;
    &__sale {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 66px;
        height: 30px;
        border: 1px solid $first-color;
        @extend %text-smaller;
        color: $first-color;
        font-weight: 600;
    }
    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 433px;
        margin-bottom: 25px;
        img {
        }
    }
    h3 {
        @extend %text-smaller;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
    }
    p {
        text-align: center;
    }
    &__price {
        @extend %text-smaller;
        font-weight: 600;
        text-align: center;
    }
}

.site-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#breadcrumbs,
.woocommerce-breadcrumb {
    width: 100%;
    max-width: $box-width;
    margin-bottom: 50px;
    padding: $col;
    border-bottom: 1px solid #f5f5f5;
    font: {
        size: 1.3rem;
    }
    line-height: 2.8rem;
    color: $font-color-gray;
    a {
        color: #000;
    }
}
.breadcrumb-page {
    #breadcrumbs,
    .woocommerce-breadcrumb {
        margin: 0;
    }
    &.--right {
        #breadcrumbs,
        .woocommerce-breadcrumb {
            text-align: right;
        }
    }
}

.woocommerce-notices-wrapper {
    width: 100%;
    max-width: $box-width;
    padding: $col;
    .woocommerce-message {
        margin-bottom: 15px;
    }
}

.onsale {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 35px;
    background-color: #ff0000;
    @extend %text-smaller;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.new {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 35px;
    background-color: #8cb0c6;
    @extend %text-smaller;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

ul.products {
    display: flex;
    flex-wrap: wrap;
    @include mq(medium) {
        flex-direction: column;
        align-items: center;
    }
    @include mq(medium, min) {
        flex-direction: row;
    }
    &.col-4 {
        li.product {
            @include mq(medium, min) {
                width: 50%;
            }
            @include mq(large, min) {
                width: 25%;
            }
        }
    }
    &.col-3 {
        li.product {
            @include mq(medium, min) {
                width: calc(100% / 3);
            }
            @include mq(large, min) {
                width: calc(100% / 3);
            }
        }
    }
    &:not(.col-4):not(.col-3) {
        li.product {
            @include mq(medium, min) {
                width: 50%;
            }
            @include mq(large, min) {
                width: 25%;
            }
        }
    }
    li.product {
        padding: 15px;
        list-style: none;
        @include mq(medium) {
            width: 100%;
            max-width: 350px;
        }

        a.woocommerce-LoopProduct-link {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: baseline;
            &:hover {
                .woocommerce-loop-product__title {
                    color: $first-color;
                }
                .woocommerce-loop-product__images img {
                    &:first-child {
                        display: none;
                    }
                    &:nth-child(2) {
                        display: block;
                    }
                }
            }
            .onsale {
                position: absolute;
                top: -10px;
                right: -10px;
            }
            .new {
                position: absolute;
                top: -10px;
                right: -10px;
                &:not(.--s) {
                    right: 0;
                }
                &.--s {
                    right: 87px;
                }
            }
            img.attachment-woocommerce_thumbnail {
                width: auto;
                max-height: 433px;
                margin-bottom: 25px;
            }
            .woocommerce-loop-product__title {
                @extend %text-smaller;
                font-weight: 400;
                text-align: center;
                max-width: 300px;
                margin-bottom: 15px;
            }
            .woocommerce-loop-product__images {
                //min-height: 433px;
                //height: 433px;
                margin-bottom: 25px;
                img {
                    width: auto;
                    max-height: 433px;
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
            .single-product-company {
                @extend %text-smaller;
                font-weight: 600;
                text-transform: uppercase;
            }
            .price {
                text-align: center;
                bdi {
                    @extend %text-smaller;
                    color: #000;
                    font-weight: 600;
                }
                del bdi {
                    @extend %text-smaller;
                    font-weight: 600;
                    color: #ff0000;
                    text-decoration: line-through;
                }
                ins bdi {
                    @extend %text-smaller;
                    color: #000;
                    font-weight: 600;
                }
            }
        }
        a.add_to_cart_button,
        .button.product_type_simple {
            display: none;
        }
    }
}

.sidebar {
    background-color: $gray-color;
    padding-top: 45px;
    &.active {
        transform: translateX(0);
    }
    @include mq(small, max) {
        position: absolute;
        left: 0;
        transform: translateX(-100%);
        z-index: 22;
        min-width: 90%;
    }
    &__btn {
        @include mq(small, min) {
            display: none;
        }
    }
    details {
        padding: 15px 30px;
        &[open] {
            summary {
                margin-bottom: 30px;
                background: {
                    image: url("../../assets/img/ico-minus.svg");
                    size: 16px 1px;
                    position: right center;
                    repeat: no-repeat;
                }
            }
        }
        label {
            display: block;
            @extend %text-smaller;
            font-weight: 500;
            margin: 0;
        }
        summary {
            list-style: none;
            @extend %text-bigger;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            background: {
                image: url("../../assets/img/ico-plus.svg");
                size: 16px 16px;
                position: right center;
                repeat: no-repeat;
            }
        }
        div {
            max-height: 200px;
            overflow: auto;
        }
        ul.product-categories {
            li {
                list-style: none;
                &.current-cat {
                    > a {
                        color: $first-color;
                    }
                }
                a {
                    @extend %text-smaller;
                    font-weight: 500;
                }
                ul.children {
                    padding-left: 20px;
                    li {
                    }
                }
            }
        }
    }
}

.select2-container {
    .select2-selection {
        display: inline-block !important;
        padding: 10px 15px;
        background-color: #fff !important;
        height: auto !important;
        font: {
            family: "Montserrat";
            weight: 400;
            size: 1.4rem;
        }
        line-height: 2.4rem;
        border: 1px solid #000 !important;
        width: 100%;
        margin-bottom: 10px !important;
        &:focus {
            border: 1px solid #000;
        }
        -webkit-appearance: none;
        -webkit-border-radius: 0 !important;
    }
    .select2-selection__arrow {
        top: 13px !important;
        right: 10px !important;
        background: {
            image: url("../../assets/img/ico-drop-down.svg");
            size: 12px 24px;
            position: center center;
            repeat: no-repeat;
        }
        b {
            border-color: transparent !important;
        }
    }
}
.woocommerce-error {
    width: 100%;
    padding: 15px;
    background-color: $gray-color;
    margin-bottom: 15px;
    li {
        list-style: none;
    }
}

.pswp__button.pswp__button--zoom {
    // display: none;
}
.pswp__zoom-wrap {
    img {
        max-height: inherit;
    }
}
