/*
Version: 1.5
Authors: Krzysztof Krasiński
*/

//MEDIA SCREEN
$breakpoints: (
    "xsmall": 480px,
    "small": 768px,
    "medium": 992px,
    "large": 1200px,
);

//DIV SIZE
$container-width: 100%; //Xpx -> box OR 100% -> full with
$box-width: 1767px;

//WEB COLORS
$first-color: #8cb0c6;
$gray-color: #f5f5f5;
//FONTS
$font-color: #2c2c2c;
$font-color-gray: #7a7b87;
$font-size: 10px;
$col: 15px;
//ROW PADDINGS
$row-top-padding: 60px;
$row-right-padding: 0;
$row-bottom-padding: 60px;
$row-left-padding: 0;
