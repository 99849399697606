footer {
    background-color: #000;
    .footer {
        @include mq(small) {
            padding-top: 50px;
        }
        @include mq(small, min) {
            padding-top: 160px;
        }
        &-title {
            display: block;
            color: #fff;
            margin-bottom: 25px;
        }
        p {
            color: #7a7b87;
        }
        &-link {
            color: #fff;
            padding-bottom: 5px;
            background: linear-gradient(transparent calc(100% - 1px), #7a7b87 10px) no-repeat;
            background-size: auto;
            background-size: 40% 100%;
            &:hover {
                background: linear-gradient(transparent calc(100% - 1px), #fff 10px) no-repeat;
                background-size: 100% 100%;
                color: #fff;
            }
        }
        &-contact {
            display: flex;
            &__logo {
                width: 51px;
                height: 51px;
                margin-right: 24px;
                object {
                    width: 51px;
                    height: 51px;
                }
            }
            &__content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                &--link {
                    font: {
                        size: 2rem;
                        weight: 500;
                    }
                    color: #fff;
                    padding-bottom: 5px;
                    background: linear-gradient(transparent calc(100% - 1px), #fff 10px) no-repeat;
                    background-size: auto;
                    background-size: 0 100%;
                    &:hover {
                        background-size: 100% 100%;
                    }
                }
                &--social {
                    margin-top: 20px;
                    a {
                        &:hover svg {
                            opacity: 0.6;
                        }
                    }
                    svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        fill: #fff;
                        transition: all 0.4s ease;
                    }
                }
            }
        }
        ul {
            list-style: none;
            li {
                a {
                    font: {
                        size: 1.7rem;
                    }
                    line-height: 2.8rem;
                    color: #7a7b87;
                    padding-bottom: 5px;
                    background: linear-gradient(transparent calc(100% - 1px), #7a7b87 10px) no-repeat;
                    background-size: auto;
                    background-size: 0 100%;
                    &:hover {
                        background: linear-gradient(transparent calc(100% - 1px), #fff 10px) no-repeat;
                        background-size: 100% 100%;
                        color: #fff;
                    }
                }
            }
        }
        &-copyrights {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 15px;
            padding-top: 120px;
            font: {
                size: 1.6rem;
            }
            line-height: 3rem;
            color: #7a7b87;
            a {
                position: relative;
                color: #7a7b87;
                padding-bottom: 5px;
                background: linear-gradient(transparent calc(100% - 1px), #7a7b87 10px) no-repeat;
                background-size: auto;
                background-size: 0 100%;
                &:hover {
                    background: linear-gradient(transparent calc(100% - 1px), #fff 10px) no-repeat;
                    background-size: 100% 100%;
                    color: #fff;
                }
                &:not(:last-child) {
                    margin-right: 20px;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 10%;
                        right: -10px;
                        width: 1px;
                        height: 80%;
                        background-color: #7a7b87;
                    }
                }
            }
        }
    }
}
