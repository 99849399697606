.categorypage {
    @include mq(small) {
    }
    @include mq(small, min) {
        //padding: 60px 0;
    }
    .empty {
        @include mq(medium) {
            display: none !important;
        }
    }
    &-margin {
        @include mq(medium, min) {
            margin-bottom: 60px;
        }
    }
    overflow: hidden;
    &-textblock {
        padding: 50px 0;
        &__title {
            @extend %h3;
            margin-bottom: 25px;
        }
        &__text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h2 {
                @extend %h3;
                margin-bottom: 25px;
            }
            h3 {
                @extend %h5;
            }
            a.btn {
                margin-top: 25px;
            }
        }
    }
    &-designers-list {
        padding: 50px 0;
        h2,
        h3 {
            @extend %h5;
            font-weight: 400;
            margin-bottom: 30px;
        }
        p {
            max-width: 570px;
        }
    }
    &-black {
        margin: 50px 0;
        padding: 60px 0;
        background-color: #000;
        color: #fff;
        h3 {
            color: #fff;
            background-color: transparent;
            &:not(.vertical-header__title) {
                @extend %h5;
                color: #fff;
                margin-bottom: 15px;
            }
        }
        object {
            width: 39px;
            height: 34px;
            margin: 15px 0 30px;
        }
        &__quote {
            text-transform: uppercase;
            font: {
                size: 2rem;
                weight: 500;
            }
            line-height: 3rem;
        }
    }
    &-imagesblock {
        padding: 60px 0;
        background-color: $gray-color;
        h3,
        h1 {
            @extend %h3;
            text-align: center;
            margin-bottom: 25px;
            font-weight: 500;
        }
        p {
            text-align: center;
        }
        a.btn {
            margin-top: 25px;
        }
    }
    &__images {
        padding: 50px 0;
    }
    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        &.--moveright {
            @include mq(medium, min) {
                img {
                    transform: scale(1.2) translateX(150px);
                }
            }
        }
    }
    &__product {
        display: flex;
        justify-content: center;
        align-items: center;
        ul.products {
            width: 100%;
            li {
                width: 100% !important;
            }
        }
    }
    &__gallery {
    }
    &__biglogo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 15px;
        object {
            max-width: 100%;
        }
    }
    &__numbers {
        padding: 50px 0;
        object {
            max-width: 100%;
        }
        &--big {
            display: block;
            @extend %h2;
            font-weight: 400;
            @include mq(small) {
                text-align: center;
            }
        }
        &--small {
            display: block;
            @extend %h5;
            font-size: 2.8rem;
            font-weight: 400;
            margin-bottom: 10px;
            @include mq(small) {
                text-align: center;
            }
        }
    }
    &__icons {
        &--box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            margin-bottom: 15px;
            object {
                width: 40px;
                height: 40px;
            }
            span {
                font: {
                    size: 2.2rem;
                    weight: 500;
                }
            }
        }
    }
    &__text {
        margin-bottom: 50px;
        h3 {
            margin-bottom: 15px;
        }
        &--box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            margin-bottom: 15px;
            .title {
                @extend %h5;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
    }
}
.categorypage-img-text {
    padding: 50px 0;
    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px;
        h2 {
            @extend %h3;
            margin-bottom: 25px;
            width: 100%;
            max-width: 570px;
        }
        p {
            width: 100%;
            max-width: 570px;
        }
    }
    &__block {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        height: 460px;
        width: 100%;
        padding: 60px;
        overflow: hidden;

        @include mq(small, min) {
            &:hover {
                .image-in {
                    transform: scale(1.1);
                }
            }
            &:hover & {
                &--title {
                    transform: translateY(0);
                }
                &--description {
                    transform: translateY(0);
                    opacity: 1;
                }
                &--link {
                    transform: translateY(0);
                    opacity: 1;
                    background-size: 100% 100%;
                }
            }

            &--title {
                transform: translateY(15px);
                transition: all 0.4s ease;
            }
            &--description {
                transform: translateY(15px);
                opacity: 0;
                transition: all 0.4s ease;
            }
            &--link {
                transform: translateY(15px);
                opacity: 0;
                transition: all 0.4s ease;
            }
        }
        .image-in {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.4s ease;
        }
        &--title {
            position: relative;
            @extend %h5;
            font-weight: 600;
            text-transform: uppercase;
            color: #fff;
            z-index: 1;
            max-width: 320px;
            margin-bottom: 35px;
        }
        &--description {
            position: relative;
            color: #fff;
            z-index: 1;
            max-width: 320px;
        }
        &--link {
            position: relative;
            color: #fff;
            z-index: 1;
            background: linear-gradient(transparent calc(100% - 1px), #fff 10px) no-repeat;
            background-size: auto;
            background-size: 0 100%;
        }
    }
}

.categorypage-coupon {
    @include mq(small) {
        padding: 30px;
    }
    @include mq(small, min) {
        padding: 60px 0;
    }
    h2 {
        @extend %h3;
        margin-bottom: 25px;
    }
    .col {
        padding: 10px;
    }
}

.categorypage-gallery {
    @include mq(small) {
        padding: 30px;
    }
    @include mq(small, min) {
        padding: 60px 0;
    }
    h2 {
        @extend %h3;
        margin-bottom: 25px;
    }
    &__sign {
        a {
            background: linear-gradient(transparent calc(100% - 1px), #000 10px) no-repeat;
            background-size: auto;
            background-size: 0 100%;
            &:hover {
                background-size: 100% 100%;
            }
        }
        @include mq(medium) {
        }
        @include mq(medium, min) {
            text-align: right;
        }
    }
}

.categorypage-video {
    margin: 60px 0;
    padding: 60px 0;
    background-color: #000;
    h3 {
        text-align: center;
        color: #fff;
        margin-bottom: 50px;
    }
    video,
    iframe {
        display: block;
        width: 100%;
        max-width: 900px;
        height: 500px;
        margin: 0 auto;
    }
    &__sign {
        width: 100%;
        max-width: 900px;
        text-align: right;
        color: #fff;
    }
}

.section-collection-list {
    margin: 50px 0;
    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h3 {
            margin-bottom: 25px;
            font: {
                family: "Montserrat";
                size: 2.6rem;
                weight: 500;
            }
            line-height: 3.6rem;
        }
    }
    .vertical-header {
        @include mq(medium, min) {
            align-items: flex-start;
            justify-content: flex-end;
        }
        &:before {
            @include mq(medium, min) {
                left: inherit;
                right: 25px;
                top: 15px;
                height: calc(100% - 15px);
            }
        }
        &__title {
            @include mq(medium, min) {
                padding: 15px 0 50px;
            }
        }
    }
}
