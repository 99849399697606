.woocommerce-thankyou-order-details {
    li {
        list-style: none;
    }
}

.woocommerce-order-details {
    background-color: $gray-color;
    position: relative;
    padding: 50px 15px;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        transform: translateX(-50%);
        background-color: $gray-color;
        z-index: -1;
    }
    &__title {
        @extend %h3;
        margin-bottom: 15px;
    }
    table {
        margin: 35px 0;
        thead {
            @include mq(small) {
                display: none;
            }
        }
        th {
            text-align: left;
            padding: 5px 5px 5px 0;
            @extend %h5;
        }
        tr {
            &.cart_item {
                @include mq(small) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        td {
            text-align: left;
            padding: 5px 5px 5px 15px;
            @extend %h5;
        }

        th {
            font-weight: 400;
        }
        td {
            font-weight: 500;
        }
    }
}
