header {
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: all 0.4s ease;
	z-index: 99;
}

.header {
	&__top {
		padding: 35px 15px;
		background: {
			image: url("../../assets/img/background-gradient-3.svg");
		}
		&--content {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	&__bottom {
		position: relative;
		justify-content: center;
		@include mq(medium) {
			display: none;
		}
		@include mq(medium, min) {
			display: flex;
		}
		nav {
			position: relative;
			display: flex;
			justify-content: center;
			width: $box-width;
		}
		ul#navigation {
			display: flex;
			list-style: none;
			> li {
				&.red {
					a {
						color: #ff0000;
					}
				}
				a {
					display: inline-flex;
					padding: 25px 12px;
					@extend %fontMenu;
					text-transform: uppercase;
					&:hover {
						color: $first-color;
					}
				}
				&.current-menu-item {
					a {
						color: $first-color;
					}
				}
				&:hover {
					> .sub-menu {
						display: flex;
					}
				}
				> .sub-menu {
					position: absolute;
					top: 100%;
					left: 0;
					background-color: #fbfbfb;
					width: 100%;
					padding: 15px 15px 25px 15px;
					justify-content: center;
					display: none;
					list-style: none;
					gap: 5%;
					&:before {
						content: "";
						position: absolute;
						top: 0;
						left: 50%;
						width: 100vw;
						transform: translateX(-50%);
						height: 100%;
						background-color: #fbfbfb;
						z-index: -1;
					}
					> li {
						// width: 20%;
						> a {
							color: #7a7b87;
							display: inline-flex;
							padding: 25px 7px 15px;
							@extend %fontMenu;
							text-transform: uppercase;
							&:hover {
								color: $first-color;
							}
						}
						&.current-menu-item {
							a {
								color: $first-color;
							}
						}
						> .sub-menu {
							display: flex;
							flex-direction: column;
							> li {
								list-style: none;
								> a {
									color: $font-color;
									display: inline-flex;
									padding: 0 7px;
									@extend %text-smaller;
									font-weight: 500;
									text-transform: capitalize;
									&:hover {
										color: $first-color;
									}
								}
								&.current-menu-item {
									a {
										color: $first-color;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&__contact {
		gap: 10px;
		flex-grow: 1;
		@include mq(medium) {
			display: none;
		}
		@include mq(medium, min) {
			display: flex;
		}
		a {
			position: relative;
			display: inline-flex;
			@extend %text-smaller;
			font-weight: 600;
			&:not(:last-child) {
				&:after {
					content: "";
					position: absolute;
					top: 20%;
					right: -5px;
					height: 60%;
					width: 1px;
					background-color: #000;
				}
			}
		}
	}
	&__logo {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		a {
			display: block;
			&:hover object {
				opacity: 0.6;
			}
		}
		object {
			width: 100%;
			max-width: 392px;
			height: 100%;
			//max-height: 40px;
			pointer-events: none;
			transition: all 0.4s ease;
			@include mq(small) {
				height: 72px;
			}
			@include mq(small, min) {
			}
		}
	}
	&__hamburger {
		align-items: center;
		transition: all 0.4s ease;
		&:hover {
			opacity: 0.6;
		}
		cursor: pointer;
		@include mq(medium) {
			display: flex;
		}
		@include mq(medium, min) {
			display: none;
		}
		svg {
			width: 30px;
			height: 8px;
		}
		span {
			display: flex;
			margin-left: 10px;
			font: {
				size: 1.7rem;
				weight: 500;
			}
			line-height: 2.7rem;
		}
		&.active {
		}
	}
	&__add {
		display: flex;
		justify-content: flex-end;
		flex-grow: 1;
		&--icons {
			display: flex;
			gap: 20px;
			margin-right: 20px;
			svg {
				max-width: 24px;
				max-height: 24px;
			}
			.search {
				@include mq(medium) {
					display: none;
				}
				@include mq(medium, min) {
					display: flex;
				}
			}
			.account {
				@include mq(medium) {
					display: none;
				}
				@include mq(medium, min) {
					display: flex;
				}
			}
			.basket {
				position: relative;
				&__circle {
					position: absolute;
					top: calc(50% - 2px);
					left: 50%;
					display: block;
					transform: translate(-50%, -50%);
					font: {
						size: 1.2rem;
					}
					color: #000;
				}
			}
		}
		&--lang {
			list-style: none;
			@include mq(medium) {
				display: none;
			}
			@include mq(medium, min) {
				display: flex;
			}
			a {
				position: relative;
				display: inline-flex;
				margin-right: 5px;
				@extend %text-smaller;
				&:not(:last-child) {
					&:after {
						content: "";
						position: absolute;
						top: 20%;
						right: -5px;
						height: 60%;
						width: 1px;
						background-color: #000;
					}
				}
			}
		}
	}
}
