.header-menu {
    &.active {
        top: 0;
        opacity: 1;
    }
    &:not(.active) {
        top: -250px;
        opacity: 0;
        pointer-events: none;
    }
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 997;

    background-color: #fff;
    overflow: auto;
    transition: all 0.4s cubic-bezier(0.23, 0.57, 0.38, 0.99);
    &__image {
        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        .image-in {
            width: 100%;
            height: 100%;
        }
        @include mq(small) {
            display: none;
        }
        @include mq(large) {
            width: calc(100% / 12 * 5);
        }

        @include mq(large, min) {
            width: calc(100% / 12 * 4);
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px 0;
        &--header {
            display: flex;
            justify-content: flex-end;
            padding: 15px;
        }
        &--content {
        }
        &--footer {
            display: flex;
            padding: 15px;
            @include mq(small, min) {
                justify-content: flex-end;
            }
        }
    }
    &__vertical {
        object {
            width: 100px;
            height: 50px;
        }
        svg {
            width: 20px;
            height: 20px;
            margin-bottom: 10px;
            transition: all 0.4s ease;
            &:hover {
                opacity: 0.6;
            }
        }
        &--phone {
            margin: 20px 0;
            padding: 0 5px;
            background: linear-gradient(-90deg, transparent calc(100% - 1px), #000 10px) no-repeat;
            background-size: auto;
            background-size: 100% 0;
            &:hover {
                background-size: 100% 100%;
            }
        }
        @include mq(small) {
            margin-bottom: 20px;
            a {
                display: none;
            }
            object {
                transform: rotate(90deg) translateY(50%);
            }
        }
        @include mq(small, min) {
            writing-mode: vertical-rl;
            object {
                transform: rotate(90deg) translateX(25%);
                margin-bottom: 60px;
            }
        }
    }
    &__lang {
        list-style: none;
        a {
            position: relative;
            display: inline-flex;
            margin-right: 5px;
            font: {
                size: 1.7rem;
                weight: 500;
            }
            &:not(:last-child) {
                &:after {
                    content: "";
                    position: absolute;
                    top: 20%;
                    right: -5px;
                    height: 60%;
                    width: 1px;
                    background-color: #000;
                }
            }
        }
    }
    &__close {
        position: relative;
        display: flex;
        align-items: center;
        top: 60px;
        cursor: pointer;
        svg {
            width: 8px;
            height: 30px;
            margin-right: 10px;
        }
        span {
            font: {
                size: 1.7rem;
                weight: 600;
            }
        }
    }
    &__nav {
        .more {
            position: relative;
            top: 2px;
            font-size: 2.2rem;
            line-height: 2.2rem;
            padding: 0 10px;
            cursor: pointer;
        }
        nav {
            margin-bottom: 15px;
            > ul {
                display: flex;
                flex-direction: column;
                li {
                    list-style: none;
                }
                > li {
                    &.current-menu-item {
                        > a {
                            color: $first-color;
                        }
                    }
                    a {
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 1.8rem;
                        line-height: 2.8rem;
                        &:hover {
                            color: $first-color;
                        }
                    }
                    > .sub-menu {
                        padding-left: 10px;
                        display: none;
                        > li {
                            &.current-menu-item {
                                > a {
                                    color: $first-color;
                                }
                            }
                            > .sub-menu {
                                padding-left: 10px;
                                display: none;

                                > li {
                                    &.current-menu-item {
                                        > a {
                                            color: $first-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &--mobile {
            @include mq(small) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;
                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
                div {
                    display: flex;
                    align-items: center;
                    a {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            @include mq(small, min) {
                display: none;
            }
        }
        &--wc {
            display: flex;
            gap: 20px;
            margin-top: 15px;
            svg {
                max-width: 24px;
                max-height: 24px;
            }
        }
    }
}
